<template>
  <b-card-code title="Modifier Diplôme">
    <b-card-text>
      <span>Veuillez modifier les coordonnées de Diplôme </span>
    </b-card-text>
    <!-- form -->
    <validation-observer
      v-if="parcours"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Filière"
              label-for="Filière"
            >
              <validation-provider
                #default="{ errors }"
                name="Filière"
                rules="required"
              >
                <v-select
                  v-model="parcours.sector"
                  :clearable="false"
                  :options="sectors"
                  label="label"
                  placeholder="filière"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Libellé"
              label-for="Libellé"
            >
              <validation-provider
                #default="{ errors }"
                name="Libellé"
                rules="required"
              >
                <b-form-input
                  v-model="parcours.title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Libellé en Arabe"
              label-for="Libellé en Arabe"
            >
              <validation-provider
                #default="{ errors }"
                name="Libellé en Arabe"
                rules="required"
              >
                <b-form-input
                  v-model="parcours.title_arabe"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé en Arabe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5>Date de Début Habilitation</h5>
            <b-form-datepicker
              id="example-datepicker"
              v-model="parcours.habilitation_start_date"
              class="mb-1"
              placeholder="Date de Début Habilitation"
            />
          </b-col>
          <b-col md="6">
            <h5>Date Fin Habilitation</h5>
            <b-form-datepicker
              id="example-datepickerr"
              v-model="parcours.habilitation_end_date"
              class="mb-1"
              placeholder="Date Fin Habilitation"
            />
          </b-col>
          <b-col md="3">
            <b-form-group>
              <h5>Active</h5>
              <b-form-checkbox
                v-model="parcours.is_active"
                name="check-button"
                switch
                inline
                @click="parcours.is_active = true"
              >
                Active
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editParcours"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      password: '',
      parcours: {},
      required,
      categories: ['licence', 'master', 'cycle préparatoire', 'cycle ingénieur'],
      sectors: [],
    }
  },
  created() {
    this.getParcours()
    this.getFilieres()
  },
  methods: {
    async getFilieres() {
      this.load = 'true'
      const response = await axios.get('/api/filieres/')
      this.sectors = response.data
      this.load = 'false'
    },
    async getParcours() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/parcours/detail/${id}`)
      this.parcours = response.data
      this.load = 'false'
    },
    async editParcours() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/parcours/update/${id}/`,
                {
                  title: this.parcours.title,
                  title_arabe: this.parcours.title_arabe,
                  habilitation_start_date: this.parcours.habilitation_start_date,
                  habilitation_end_date: this.parcours.habilitation_end_date,
                  sector: this.parcours.sector.id,
                  is_active: this.parcours.is_active,
                },
              )
            this.$router.push('/parcours')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Parcours Modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
